<template>
    <div class="consent_modal_popup">
        <div class="consent_modal_popup_scroll">
            <div class="consent_modal_popup_center">
                <div class="consent_modal_box">
                    <div class="consent_modal_body">
                        <h2 class="popup_heading">Consent Form Details </h2>
                        <form @submit.prevent="consentFormBeforeSubmit">
                            <div class="form-group">
                                <p>As the caregiver, I, <label class="tab_label">[{{ userName }}]</label> , am entrusted with the responsibility of
                                    <label class="tab_label" v-if="selectedRelation">
                                     [{{ selectedRelation }}]
                                    </label>
                                    <template v-else>
                                        <select v-model="relationship" name="relationship" class="form-control" v-validate="'required'"
                                            v-bind:class="{'is-invalid': errors.has('relationship') }" data-vv-as="record for">
                                            <option value="">state the relationship caregiver has with the patient</option>
                                            <option value="self">Self</option>
                                            <option value="mother">Mother</option>
                                            <option value="father">Father</option>
                                            <option value="sister">Sister</option>
                                            <option value="brother">Brother</option>
                                            <option value="partner">Spouse/Partner</option>
                                            <option value="uncle">Uncle</option>
                                            <option value="aunt">Aunt</option>
                                            <option value="other">Other</option>
                                        </select>
                                        <small class="invalid-feedback"
                                        v-if="errors.has('relationship')">{{ errors.first('relationship') }}</small>
                                    </template> . I have received the consent from <label class="tab_label">[{{ patientName }}]</label> , which empowers me to
                                    act on their behalf. </p>
                                <p>Upon receiving the consent, I am deemed authorized to teleconsult and divulge medical
                                    records, history and other personal information important for the teleconsulting
                                    session with the doctor/clinician/professional on LBIL (<a href="https://lbil.ai/" target="_blank">www.lbil.ai</a>) on behalf of
                                    <label class="tab_label">({{ patientName }})</label>. </p>
                                <p>As a caregiver, I am taking full responsibility for disclosing the medical records,
                                    history and personal information of <label class="tab_label">({{ patientName }})</label>. </p>
                            </div>
                            <div class="form-group">
                                <h6>Please select the scenario below that best describes your reason for teleconsulting
                                    on behalf of <label class="tab_label">({{ patientName }})</label>. This will help us understand the context of the
                                    teleconsultation.</h6>
                            </div>
                            <div class="form-group">
                                <label class="cstm_radio_label" for="case1" :class="selectedOption == 'case1' ? 'selected' : ''"><input type="radio" name="selectedOption" value="case1" id="case1" v-model="selectedOption" hidden v-validate="'required'" v-bind:class="{'is-invalid': errors.has('selectedOption') }"  data-vv-as="select option"> <span class="custom_radio"></span> <span>I further declare that <label class="tab_label">({{ patientName }})</label> is mentally and physically incapacitated due to <input type="text" class="form-control" name="reason" v-model="reason" v-validate="'required'" v-bind:class="{'is-invalid': errors.has('reason') }"  data-vv-as="relation" v-if="selectedOption == 'case1'" placeholder="(state the specific reason)"/> <label v-else class="tab_label">(state the specific reason)</label> , and, therefore, will need my
                                    assistance to teleconsult on their behalf.</span></label>
                                <small class="invalid-feedback"
                                v-if="errors.has('reason')">{{ errors.first('reason') }}</small>
                                <p>OR</p>
                                <label class="cstm_radio_label" for="case2" :class="selectedOption == 'case2' ? 'selected' : ''"><input type="radio" name="selectedOption" value="case2" id="case2" v-model="selectedOption" hidden v-validate="'required'" v-bind:class="{'is-invalid': errors.has('selectedOption') }"  data-vv-as="select option"> <span class="custom_radio"></span> <span> I further declare that <label class="tab_label">({{ patientName }})</label> is a minor (18 years and under);
                                    therefore, I have authorization to teleconsult on their behalf. </span></label>
                                <p>OR</p>
                                <label class="cstm_radio_label" for="case3" :class="selectedOption == 'case3' ? 'selected' : ''"><input type="radio" name="selectedOption" value="case3" id="case3" v-model="selectedOption" hidden v-validate="'required'" v-bind:class="{'is-invalid': errors.has('selectedOption') }"  data-vv-as="select option"> <span class="custom_radio"></span> <span> I further declare that <label class="tab_label">({{ patientName }})</label> would like me to teleconsult on their behalf and has provided written consent in a sound state of mind. I understand that
                                    the doctor/clinician/professional may request this written consent during the
                                    teleconsulting session. </span></label>
                                <small class="invalid-feedback d-block"
                                v-if="errors.has('selectedOption')">{{ errors.first('selectedOption') }}</small>
                            </div>
                            <div class="form-group">
                                <p>Finally, I understand that Lymphomap Biotech India Pvt. Ltd. (LBIL) and Lymphomap
                                    Inc. (the Companies that own <a href="https://lbil.ai/" target="_blank">www.lbil.ai</a>) are not liable for any fraudulent means of
                                    data exchange of information or invasion of privacy of <label class="tab_label">({{ patientName }})</label> I’ll be
                                    teleconsulting on behalf of and that this e-consent form acknowledges the
                                    authorization of me <label class="tab_label">({{ userName }})</label> , teleconsulting on <label class="tab_label">({{ patientName }})</label> behalf.
                                </p>
                            </div>
                            <div class="form-group">
                                <p class="mb-0">Signature of Caregiver</p>
                                <h5 style="text-transform: uppercase;">{{ userName }}</h5>
                            </div>
                            <div class="form-group">
                                <p class="mb-0">Email Address of Caregiver </p>
                                <h5>{{ userEmail }}</h5>
                            </div>
                            <div class="form-group">
                                <p class="mb-0">Date of when the consent form is being signed </p>
                                <h5>{{ date | LocalDateFormat }}</h5>
                            </div>
                            <div class="btn_group text-center">
                                <button class="btn btn-danger" @click="consentFormPopupOpenMethod">Cancel</button>
                                <button class="btn btn-success" type="submit" :disabled="submitted">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable */

import { bus } from '@/main';
import toast from '@/services/toast';

export default {
  components: {
  },
  props: ['patientName', 'userInfo', 'recordFor'],
  data() {
    return {
      reason:'',
      relationship: '',
      patient_name: '',
      userName:'',
      selfUserId:'',
      date:new Date(),
      userEmail:'',
      selectedOption:'',
      selectedRelation:'',
      submitted:false
    }
  },
  computed: {

  },
  mounted() {
    this.userName = this.userInfo.first_name + ' ' + this.userInfo.last_name;
    this.selfUserId = this.userInfo.id;
    this.userEmail = this.userInfo.email;
    this.selectedRelation = this.recordFor;
  },
  methods: {
    consentFormPopupOpenMethod(){
      bus.$emit('consentFormPopupStateBus', false)
      document.body.classList.remove('popup_open_body');
    },
    consentFormBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
            relationship: this.selectedRelation || this.relationship,
            patient_name: this.patientName,
            case_1:this.selectedOption == 'case1' ? true : false,
            case_2:this.selectedOption == 'case2' ? true : false,
            case_3:this.selectedOption == 'case3' ? true : false
          }
          if(this.reason){
            data.case_1_reason = this.reason;
          }
         this.$store.dispatch('consentFormUpdateAction', data).then((res) => {
          toast.success(res?.data?.msg);
          this.submitted =  false;
          if(res.data.data.id){
            bus.$emit('consentIdAfterSuccessFormBus', res.data.data.id)
          }
         }).catch((err) => {
          toast.error(err?.data?.msg);
          this.submitted =  false
         })
         return;
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.consent_modal_popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 11;
    background: rgba($color: #000000, $alpha: 0.92);

    .consent_modal_popup_scroll {
        overflow-y: auto;
        height: 100svh;

        &::-webkit-scrollbar {
            width: 8px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #333333;
        }
    }

    .consent_modal_popup_center {
        color: #ffffff;
        font-family: 'MyriadProRegular', sans-serif;
        display: table;
        position: relative;
        width: 100%;
        height:100%;
    }

    .consent_modal_box {
        margin: 0;
        display: table-cell;
        vertical-align: middle;
    }

    .consent_modal_body {
        background: #000;
        max-width: 860px;
        width: 100%;
        margin: 0px auto;
        border-radius: 5px;
        padding: 20px 25px;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        color: #ffffff;
        box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
        .popup_heading {
            font-size: 28px;
            color: #ffd400;
            font-family: 'MyriadProRegular', 'Lato', sans-serif;
            margin-bottom: 20px;

            @media only screen and (max-width:767px) {
                font-size: 18px;
            }
        }
        label.tab_label {
            display: inline !important;
            margin: 0;
            background: rgba(255, 255, 255, 0.1);
            line-height: 1;
            color: #888;
            padding: 1px 5px;
            border-radius: 2px;
            vertical-align: middle;
            word-wrap: break-word;
        }
        p{font-size:16px;color:#999999;line-height:1.6;}
        h6{font-size:16px;color:#999999;font-weight:600;}
        h5{font-size:18px;color:#ffffff;}
        a{color:#87cefa;text-decoration:none;&:hover{color: #ffe400}}
        .cstm_radio_label{
            border:2px solid #ffd400;
            border-radius:3px;
            padding:5px 10px;
            margin: 10px 0 20px;
            cursor:pointer;
            &.selected{
                background:#ffd400;
                color:#000000;
                .tab_label{background:#000000;}
                .form-control {background:#000000;}
            }
        }
    }

    .btn-danger {
        font-size: 16px;
        background: #ff7070;
        color: #fff;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        padding: 10px 5px;
        min-width: 100px;
        line-height: 1;
        border-radius: 3px !important;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            background: #b41818;
            color: #fffcc4;
        }
    }

    .btn-success {
        font-size: 16px;
        background: #459238;
        color: #fff;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        padding: 10px 5px;
        min-width: 100px;
        line-height: 1;
        margin-left: 10px;
        border-radius: 3px !important;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            background: #87cefa;
            color: #22292f;
        }
    }
    .form-control {
        background: rgba(255, 255, 255, 0.1);
        border: none;
        color: #ffffff;
        font-size: 16px;
        display: inline-block;
        width: auto;
        box-shadow: none !important;
        padding: 2px 10px;
        height: auto;
        option{color:#000000;}


        &::-webkit-input-placeholder {
            color: #686868;
        }

        &::-moz-placeholder {
            color: #686868;
        }

        &::-ms-input-placeholder {
            color: #686868;
        }

        &:focus::-webkit-input-placeholder {
            font-size: 0px;
        }

        &:focus::-moz-placeholder {
            font-size: 0px;
        }

        &:focus::-ms-input-placeholder {
            font-size: 0px;
        }
    }
}
</style>