/*eslint-disable */

import Multiselect from 'vue-multiselect'
import ScheduleCalendar from './ScheduleCalendar.vue'
import axios from 'axios';
import blurbForAppointment from '../blurb-for-appointment/index.vue';
import { bus } from '@/main';
import consentFormPopup from '@/components/private/consent-form-for-appointment/index.vue';
import { mapGetters } from 'vuex';
import root from '@/_helpers/roots';
import toast from '@/services/toast';
import uploadMedicalRecords from '@/components/private/health-record-main-popup';
import videoRecording from '@/components/private/video-recording-data/index.vue';

// You need a specific loader for CSS files
let appointmentApiUrl = root.appointmentApiUrl.link;
export default {
  name: 'create-appointmet-form',
  components: {
    'video-record': videoRecording,
    Multiselect,
    'blurb-for-appointment': blurbForAppointment,
    'upload-medical-record': uploadMedicalRecords,
    ScheduleCalendar,
    'consent-form' : consentFormPopup
  },
  props: [],
  data() {
    return {
      full_name: '',
      dob: '',
      height: '',
      weight: '',
      blood_type: '',
      disease: '',
      symptoms: '',
      sendSymptomsArr: [],
      record: '',
      submitted: false,
      selfUserDetailData: {},
      datetime: '',
      subcategory: '',
      selfUserInfo: {},
      symptomsArr: [
        { name: 'Fever', code: 'Fever' },
        { name: 'Acne', code: 'Acne' },
        { name: 'Blocked Nose', code: 'Blocked Nose' },
        { name: 'Runny Nose', code: 'Runny Nose' },
        { name: 'Itching', code: 'Itching' },
        { name: 'Headache', code: 'Headache' },
        { name: 'Vomiting', code: 'Vomiting' },
        { name: 'Constipation', code: 'Constipation' },
        { name: 'Throat pain', code: 'Throat pain' },
        { name: 'Rashes', code: 'Rashes' },
        { name: 'Sneezing', code: 'Sneezing' },
        { name: 'Hairfall', code: 'Hairfall' },
        { name: 'Abdominal Pain', code: 'Abdominal Pain' },
        { name: 'Spots On Skin', code: 'Spots On Skin' },
        { name: 'Obesity', code: 'Obesity' },
        { name: 'Gas', code: 'Gas' },
        { name: 'Erectile Dysfunction', code: 'Erectile Dysfunction' },
        { name: 'Bone Pain', code: 'Bone Pain' },
        { name: 'Dry Skin', code: 'Dry Skin' },
      ],
      uploadedHRList: [],
      comment: '',
      videRecordState: false,
      videRecordData: '',
      uploadedVideoData: '',
      appoinmentWithId: '',
      gender: '',
      myFavDoctor: false,
      draftSymptoms: '',
      draftDisease: '',
      reBookingAppointment: false,
      availableSchedule: '',
      uploadedVideoLink: null,
      appointmentId: '',
      blurbOfAppointmentState: false,
      uploadMedicalRecordsModal: false,
      hRData: null,
      doctorInfo: null,
      medicalRecord:'',
      consentFormPopupState:false,
      recordFor:'',
      record_for:'',
      filteredHealthRecord:[]
    }
  },
  computed: {
  },
  mounted() {
    this.appoinmentWithId = this.$route.params.doctorID;
    this.fetchDoctorDetails(this.appoinmentWithId);
    this.$store.dispatch('getSelfUserInfoApiFun').then((res) => {
      this.selfUserInfo = res.data.data;
      // this.full_name = this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name;
      this.dob = this.selfUserInfo.dob;
      this.height = this.selfUserInfo.profile.height;
      this.weight = this.selfUserInfo.profile.weight;
      this.blood_type = this.selfUserInfo.profile.blood_type;
      this.gender = this.selfUserInfo.gender;
    })
    this.fetchHealthRecords(false);
    bus.$on('videRecordDataBus', (data) => {
      this.videRecordData = data;
      this.renderUploadVideo(data);
    });
    bus.$on('videRecordStateBus', (data) => {
      this.videRecordState = data;
    });
    bus.$on('blurbOfAppointmentStateBus', (data) => {
      this.blurbOfAppointmentState = data;
    });
    bus.$on('uploadMedicalRecordsModalBus', (data) => {
      this.uploadMedicalRecordsModal = data;
      this.fetchHealthRecords(true);
    });
    bus.$on('consentFormPopupStateBus', (data) => {
      this.consentFormPopupState = data;
    });
    bus.$on('consentIdAfterSuccessFormBus', (consentId) => {
      this.bookAppointmentWithConsentFormBeforeSubmit(consentId);
    });
  },
  methods: {
    consentFormPopupOpenMethod(){
      this.consentFormPopupState = true;
      document.body.classList.add('popup_open_body');
    },
    fetchDoctorDetails(doctorId) {
      // Dispatch action to fetch doctor details
      this.$store.dispatch('getDoctorDetailByIdFun',  {id:doctorId}).then((res) => {
        const data = res.data.data.data;
        this.doctorInfo = data;
        this.appointmentInfo = data.latest_appointment_details;
        this.myFavDoctor = data.is_favourite;
        this.availableSchedule = data.schedule;
      })
    },
    fetchHealthRecords(flag) {
      this.$store.dispatch('getHelthRecordsListApiFun').then(res => {
        this.uploadedHRList = res.data.data;
        if(flag){
          if(this.record_for == 'self'){
            this.filterSelfRecordsMethod();
          }else{
            this.filterOtherRecordsMethod();
          }
        }else{
          this.filteredHealthRecord = this.uploadedHRList;
        }
      })
    },
    healthRecordModal() {
      document.body.classList.add('popup_open_body');
      this.uploadMedicalRecordsModal = true;
    },
    blurbTextPopupOpened() {
      this.blurbOfAppointmentState = true;
      document.body.classList.add('popup_open_body');
    },
    renderUploadVideo(videRecordData) {
      var __self = this
      var blob = new Blob(videRecordData, { type: 'video/webm' })
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var videoBase64 = reader.result;
        __self.uploadedVideoLink = videoBase64;
      }
    },
    recordDataGetMethod(){
      if (this.record && this.record.user) {
        this.medicalRecord = this.record.id;
        let data = this.uploadedHRList.find(item => {
          if (item.id == this.medicalRecord) {
            this.full_name = item.first_name + ' ' + item.last_name
            this.dob = item.dob
            this.recordFor = item.record_for
            this.height = item.height
            this.weight = item.weight
            this.blood_type = item.blood_type
            this.gender = item.gender
          }
        });
        return data
      } else {
        if(this.record && this.record.values.length > 0){
          this.medicalRecord = this.record.values[0].id;
        }
        this.full_name = this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name
        this.dob = this.selfUserInfo.profile.dob
        this.height = this.selfUserInfo.profile.height
        this.weight = this.selfUserInfo.profile.weight
        this.blood_type = this.selfUserInfo.profile.blood_type
        this.gender = this.selfUserInfo.gender
      }
    },
    
    async bookAppointmentFormBeforeSubmit() {
      if (!await this.$validator.validateAll()) {
        return;
      }
      if (this.record_for == "other") {
        this.consentFormPopupOpenMethod();
        return;
      }
      this.submitted = true;
    
      const appointmentData = {
        full_name: this.full_name,
        dob: this.dob,
        height: this.height,
        weight: this.weight,
        blood_type: this.blood_type,
        gender: this.gender,
        symptoms: this.symptoms,
        medical_record: this.medicalRecord,
        doctor_id: this.appoinmentWithId,
        appointment_time: this.datetime
      };
    
      if (this.comment) {
        appointmentData.message = this.comment;
      }
    
      if (this.videRecordData) {
        const blob = new Blob(this.videRecordData, { type: 'video/webm' });
        this.uploadedVideoLink = blob;
        
        try {
          const videoBase64 = await this.readFileAsDataURL(blob);
          appointmentData.video_file = videoBase64.replace(/^data:video\/(webm);base64,/, "");
        } catch (error) {
          console.error('Error processing video:', error);
          this.submitted = false;
          return;
        }
      }
    
      try {
        const res = await axios.post(appointmentApiUrl, appointmentData);
        this.appointmentId = res.data.data.id;
        
        const notificationPayload = {
          type: 'appointment_created',
          data_id: this.appointmentId,
          message: "Appointment has been created"
        };
        // Uncomment if needed:
        // await this.$store.dispatch('postNotificationFunc', notificationPayload);
        
        this.blurbTextPopupOpened();
      } catch (err) {
        toast.error(err.response?.data?.msg || "Request Failed");
      } finally {
        this.submitted = false;
      }
    },
    
    readFileAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    
    async bookAppointmentWithConsentFormBeforeSubmit(consentId) {
      if (!await this.$validator.validateAll()) {
        return;
      }

      this.submitted = true;
    
      const appointmentData = {
        full_name: this.full_name,
        dob: this.dob,
        height: this.height,
        weight: this.weight,
        blood_type: this.blood_type,
        gender: this.gender,
        symptoms: this.symptoms,
        medical_record: this.medicalRecord,
        doctor_id: this.appoinmentWithId,
        appointment_time: this.datetime,
        consent:consentId
      };
    
      if (this.comment) {
        appointmentData.message = this.comment;
      }
    
      if (this.videRecordData) {
        const blob = new Blob(this.videRecordData, { type: 'video/webm' });
        this.uploadedVideoLink = blob;
        
        try {
          const videoBase64 = await this.readFileAsDataURL(blob);
          appointmentData.video_file = videoBase64.replace(/^data:video\/(webm);base64,/, "");
        } catch (error) {
          console.error('Error processing video:', error);
          this.submitted = false;
          return;
        }
      }
    
      try {
        const res = await axios.post(appointmentApiUrl, appointmentData);
        this.appointmentId = res.data.data.id;
        
        const notificationPayload = {
          type: 'appointment_created',
          data_id: this.appointmentId,
          message: "Appointment has been created"
        };
        // Uncomment if needed:
        // await this.$store.dispatch('postNotificationFunc', notificationPayload);
        
        this.blurbTextPopupOpened();
      } catch (err) {
        toast.error(err.response?.data?.msg || "Request Failed");
      } finally {
        this.submitted = false;
      }
    },

    closeCreateAppointmentModal() {
      bus.$emit('bookAppointmentModalBus', false);
      document.body.classList.remove('popup_open_body');
    },
    videoRecordingPopupOpen() {
      this.uploadedVideoLink = null;
      window.scrollTo(0, 0);
      document.body.classList.add('popup_open_body');
      this.videRecordState = true;
    },
    filterSelfRecordsMethod(){
      this.record = '';
      this.filteredHealthRecord = this.uploadedHRList.filter(record => record.record_for === 'self');
    },
    filterOtherRecordsMethod(){
      this.record = '';
      this.filteredHealthRecord = this.uploadedHRList.filter(record => record.record_for != 'self');
    }
  },
  beforeRouteLeave: function (to, from, next) {
    document.body.classList.remove('popup_open_body');
    next();
  },
  watch: {
    record_for(newVal, oldVal){
      if(newVal == 'self'){
        this.full_name = this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name;
        this.filterSelfRecordsMethod();
      }else{
        this.full_name = '';
        this.filterOtherRecordsMethod();
        this.$validator.reset();
      }
    }
  },
} 